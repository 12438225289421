/** @jsx jsx */
import { jsx, Button, Container, Styled} from 'theme-ui'

import SEO from "../components/seo"

const ThankYou = () => (
  <section>
    <SEO title="Thank You" />
    <Container>
      <div sx={{px: 5, textAlign: "center", mt: 6}}>
        <iframe src="https://giphy.com/embed/eeUJaTwsHh3tswkaYm" width="384" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/eeUJaTwsHh3tswkaYm">via GIPHY</a></p>
        <Styled.h1>Thank You</Styled.h1>
      </div>
    </Container>
  </section>
)

export default ThankYou
